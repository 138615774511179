import React from 'react';
import { List, Button, InputItem, WhiteSpace  } from 'antd-mobile';
import axios from 'axios'


export default class Mercatino extends React.Component{

 
  state = {
    value: '',
    risultato: [],
    ricerca: false,
    studente: {},
    ISBN: '',
    libro: {}
  };
  infoautore=(idautore)=>{
    axios.get('/api/visualizza?id='+idautore, this.config).then(res => {
      console.log(res);
   
      console.log(res.data.studente.name);
      return res.data.studente.name;
 
  })
 }
  config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("COToken")
    },
  };
  onChange= (value) => {
    this.setState({'value': value });
    console.log(this.state);
  };
 
  clear = () => {
    this.setState({ value: '' });
  };

 
render(){
  const Item = List.Item ;
  const axiosInstance = axios.create({
    
    timeout: 2000,
    headers:  {Authorization: localStorage.getItem("COToken")}
  });
  

  const invia= () => {
    axios.get('/api/mostralibri?isbn='+this.state.ISBN, config).then(res => {
      console.log(res);
      this.setState({ libro: res.data});})

    axios.get('/api/cercavendita?isbn='+this.state.ISBN, config).then(res => {
      console.log(res);
      this.setState({ricerca: true, risultato: res.data});
      
    })
  }


  let config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("COToken"),
    },
  };
  
  const listaAnnunci = this.state.risultato.map((name)=><>
  <Item extra="Venditore" thumb={"https://eu.ui-avatars.com/api/?name="+name.nomevenditore+"&rounded=true&background=random&size=75&color=fff"} ><><b>{name.nomevenditore}</b></></Item>
  <Item wrap multipleLine  extra={<span>Note</span>}>{name.note}</Item>
  </>)
    return (
      <div>
     {this.state.ricerca===false&& <><List style={{width: '300px',marginTop:'-15%'}}><InputItem
      name='ISBN'
       
      clear
      type="number"
      placeholder="non inserire lettere"
      onChange={(value)=>{this.setState({ISBN: value}); console.log(this.state);}}
    >ISBN</InputItem></List>
     <Button  onClick= {()=>invia()}style={{cursor:'pointer', fontFamily:'Righteous'}}>Invia</Button></>}
      {this.state.ricerca===true&&this.state.libro.length===0&&
      
         <><List style={{marginTop:'-15%', width:'300px'}}><InputItem
      name='ISBN'
      style={{width: '300px'}} 
      clear
      type="number"
      placeholder="non inserire lettere"
      onChange={(value)=>{this.setState({ISBN: value}); console.log(this.state);}}
    >ISBN</InputItem></List>
     <Button  onClick= {()=>invia()}style={{cursor:'pointer', fontFamily:'Righteous'}}>Invia</Button>
           <p style={{marginLeft:'3%', marginRight:'3%',color:'#888', fontSize:'16px', }}> Spiacente, questo libro non è presente sul nostro database.</p></>}
      {this.state.ricerca===true&& this.state.libro &&
                   <>
                    <WhiteSpace/>
                    <WhiteSpace/>
                    <WhiteSpace/>
                    <WhiteSpace/>
                    <WhiteSpace/>
                    <WhiteSpace/>
                    <Button style={{backgroundColor: 'black', color:'white'}} onClick={()=>{this.setState({ricerca:false})}}>Indietro</Button>
                    <WhiteSpace/>
                    <WhiteSpace/>
                    <WhiteSpace/>
                    <WhiteSpace/>
                    <WhiteSpace/>

                   <img style={{marginTop:"-50px",height: '200px'}}src='https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fmaxcdn.icons8.com%2FShare%2Ficon%2Fios7%2FFiles%2Fopen_book1600.png&f=1&nofb=1'/>
                   <center>
                   <List style={{ width:"300px"}}>
                  
                   <Item  extra={<b>{this.state.libro.titolo}</b>}>Titolo</Item>
                   <Item extra={this.state.libro.autori}>Autori</Item>
                   <Item extra={this.state.libro.isbn}>Codice ISBN</Item>
                  </List>
                  <WhiteSpace/>
                  </center>
                  <h2><b>Annunci</b></h2>
                  <List >{listaAnnunci}</List>
                  <WhiteSpace/>
                    <WhiteSpace/>

                  </>}
      
  </div>
);

    }}
