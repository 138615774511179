import { Tabs } from 'antd-mobile';
import React from 'react';
import Studenti from './studenti';
import Profilo from './profilo';
import Mercatino from './mercatino';
import Tutor from './tutor';

export default class Menu extends React.Component{
  refreshPage = (hash)=>{
    if (hash){window.location.hash = hash}
  
     }

render(){
    const tabs = [
        { title: 'Studenti' },
        {title: 'Tutor'},
        { title: 'Mercatino' },
        { title: 'Il mio profilo' },
        
      ];
      const reindirizza = ()=>{
        if(localStorage.getItem("COToken")===null){
        
          window.location = "/accedi";}
        return null
         }
  const calcolaPagina=()=>{
    switch(window.location.hash){
      default: case "#tutor": return 1;
      case "#profilo": return 3;
       case "#mercatino" : return 2;
      case "#studenti" : return 0;
    }}
  
    return (
      <div>
        {reindirizza()}
      <Tabs tabs={tabs}
      initialPage={calcolaPagina()}
      tabBarPosition="bottom"
      renderTab={tab => <span >{tab.title}</span>}
      animated = {true}
      tabBarUnderlineStyle = {{backgroundColor: "black", border: "1px solid black"}}
      useOnPan = {true}
      tabBarActiveTextColor = "black"
      
    >
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '500px', backgroundColor: '#fff' }}>
        <Studenti/>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '500px', backgroundColor: '#fff' }}>
        <Tutor/>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '500px', backgroundColor: '#fff' }}>
        <Mercatino/>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '500px', backgroundColor: '#fff' }}>
      <Profilo/>
      </div>
      
    </Tabs>
    
  </div>
);
}

}