import React from 'react';
import { List,  InputItem, WhiteSpace  } from 'antd-mobile';
import axios from 'axios'
import {Link } from 'react-router-dom'
import { Form, Input, Button, Checkbox, Select } from 'antd';
export default class OpenDay extends React.Component{

constructor(props){
	super(props);
	this.state={ mappaore:[], riferimento: ""}
	axios
	.get('/api/postiopenday')
	.then((res) => {
	  this.setState({ mappaore: res.data.mappaore});
	  console.log(this.state);
})
}
 
render(){
	
		const onFinish = (values) => {
      console.log(values);
      axios
      .post('/api/prenotaopenday', values)
      .then((res) => {
        if(res.data.message=="Prenotazione inserita con successo");
        window.location = "/openday/ben_fatto";
        if(res.data == "Posti Esauriti") window.location = "/openday/postiesauriti"
      })
     
		};

		const { Option } = Select;
		const lista = this.state.mappaore.map((name)=>
		<Option value={name.fascia}>{name.fascia}</Option>)
		
    return (
        
     
    <div style={{backgroundImage: 'url("http://ultra.altervista.org/accoglienza/cavourridotto2.png")',  backgroundSize: 'cover', backgroundRepeat:'no-repeat'}}>
    

    	<center>
              
    
      <br/>
    
      <h2 style={{
  marginTop:'10%',
  backgroundColor: 'white',
  width:'80%',
  padding:'2%',
  color:'#227b23',
  fontFamily: 'Montserrat',
 border: '5px solid #227b23'
}}>Vivi un giorno al Liceo Cavour! 
      <br/>
      <br/>
      <img src='https://ultra.altervista.org/logoscuro.png' style={{width: "200px"}}/>
      <br/>
    
      </h2>
      <br/>
      <a href="#modulo" style={{cursor: 'pointer', color: 'black'}}>
      	<div style={{width:'100px', backgroundColor:'white', borderRadius: '100000px'}} >
      	<svg style={{fill: "#227b23"}} version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000"y>
<g><path d="M573.6,736.3l385.9-331.2c40.6-39.1,40.7-102.4,0.1-141.4c-40.6-39-106.5-39-147.2,0.1L498.6,533.1L186.1,264.9c-40.6-39.1-106.2-39.5-146.3-1c-40.1,38.5-39.7,101.5,1,140.6l381.4,327.3c1.4,1.5,2.7,3.1,4.2,4.5C467,775.4,532.9,775.3,573.6,736.3z"/></g>
</svg>
        </div>
      </a>
      </center>
      <div id='modulo' class="container"style={{paddingTop:'3%',
  marginTop:'5%',
  paddingBottom:'3%',
  marginBottom:'2%',
  backgroundColor:'white'}}>




	<Form
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      initialValues={{
        
      }}
      onFinish={onFinish}
      
      autoComplete="off" style={{maxWidth:'600px', margin: 'auto'}}>
		<h3 class="my-4">Compila il modulo</h3>
		<hr class="my-4" />
		<div class="form-group mb-3 row"><label style={{ display: 'block',cursor: 'pointer',marginBottom: '12px', fontFamily: 'Montserrat', fontSize:'large'}} for="nome3" class="col-md-5 col-form-label">Nome dello studente</label>
			<div class="col-md-7">

			<Form.Item
        
        name="nomestud"
        rules={[
          {
            required: true,
            message: 'Inserisci un nome',
          },
        ]}
      >
		  <Input style={{  fontFamily: 'Montserrat',outline: 'none',userSelect: 'none',boxSizing: 'border-box',padding: '1em',border: '1px solid black',borderRadius: '3px',width: '100%'}}/>
      </Form.Item>
</div>
		</div>
		<div class="form-group mb-3 row"><label style={{ display: 'block',cursor: 'pointer',marginBottom: '12px', fontFamily: 'Montserrat', fontSize:'large'}} for="cognome4" class="col-md-5 col-form-label">Cognome dello studente</label>
			<div class="col-md-7">	<Form.Item
        
        name="cognomestud"
        rules={[
          {
            required: true,
            message: 'Inserisci un cognome',
          },
        ]}
      >
		  <Input style={{  fontFamily: 'Montserrat',outline: 'none',userSelect: 'none',boxSizing: 'border-box',padding: '1em',border: '1px solid black',borderRadius: '3px',width: '100%'}}/>
      </Form.Item></div>
		</div>
		<div class="form-group mb-3 row"><label style={{ display: 'block',cursor: 'pointer',marginBottom: '12px', fontFamily: 'Montserrat', fontSize:'large'}} for="cognome4" class="col-md-5 col-form-label">Nome del genitore o tutore</label>
			<div class="col-md-7">	<Form.Item
        
        name="nomegen"
        rules={[
          {
            required: true,
            message: 'Inserisci un nome',
          },
        ]}
      >
		  <Input style={{  fontFamily: 'Montserrat',outline: 'none',userSelect: 'none',boxSizing: 'border-box',padding: '1em',border: '1px solid black',borderRadius: '3px',width: '100%'}}/>
      </Form.Item></div>
		</div>
		<div class="form-group mb-3 row"><label style={{ display: 'block',cursor: 'pointer',marginBottom: '12px', fontFamily: 'Montserrat', fontSize:'large'}} for="cognome4" class="col-md-5 col-form-label">Cognome del genitore o tutore</label>
			<div class="col-md-7">	<Form.Item
        
        name="cognomegen"
        rules={[
          {
            required: true,
            message: 'Inserisci un cognome',
          },
        ]}
      >
		  <Input style={{  fontFamily: 'Montserrat',outline: 'none',userSelect: 'none',boxSizing: 'border-box',padding: '1em',border: '1px solid black',borderRadius: '3px',width: '100%'}}/>
      </Form.Item></div>
		</div>
		<div class="form-group mb-3 row"><label style={{ display: 'block',cursor: 'pointer',marginBottom: '12px', fontFamily: 'Montserrat', fontSize:'large'}} for="numero-di-telefono5" class="col-md-5 col-form-label">Indirizzo email del genitore o tutore</label>
			<div class="col-md-7">	<Form.Item
        
        name="email"
        rules={[
          {
            required: true,
            message: 'Inserisci un indirizzo email',
          },
        ]}
      >
		  <Input style={{  fontFamily: 'Montserrat',outline: 'none',userSelect: 'none',boxSizing: 'border-box',padding: '1em',border: '1px solid black',borderRadius: '3px',width: '100%'}}/>
		  
      </Form.Item>
      <small  class="form-text text-muted">A questo indirizzo sarà inviata una mail di conferma</small></div>
		</div>
		<div class="form-group mb-3 row"><label style={{ display: 'block',cursor: 'pointer',marginBottom: '12px', fontFamily: 'Montserrat', fontSize:'large'}} for="classe7" class="col-md-5 col-form-label">Giorno e Ora</label>
			<div class="col-md-7">
			<Form.Item
        
        name="fasciaoraria"
      >
				<Select  class="form-select custom-select" id="classe7">
					{lista}
				</Select>
				</Form.Item></div>
		</div>
		
		

		<hr class="my-4" />
        <p style={{
  fontFamily:'Montserrat',
  color:'#1ea672',
  fontWeight:'bold'
  
} }id="fineform" style={{ color:'#227b23', textAlign:'center'}}>Prenotandoti, confermi di aver compreso <Link to='/openday/dettagli' style={{color:'black'}} >l'informativa dedicata</Link>. Vengono mostrate a schermo solo le fasce orarie che al caricamento della pagina avevano ancora posti disponibili.</p>
		<div class="form-group mb-3 row"><label style={{ display: 'block',cursor: 'pointer',marginBottom: '12px', fontFamily: 'Montserrat', fontSize:'large'}} for="invia12" class="col-md-5 col-form-label"></label>
			<div class="col-md-7"> <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button style={{
  backgroundColor: '#227b23',
  border:'0px',
  color:'white',
  borderRadius: '.25rem',
  padding: '.5rem 1rem .5rem 1 rem',
fontSize: '1rem'
  
}}  type="primary" htmlType="submit">
          Invia
        </Button>
      </Form.Item></div>
		</div>
	</Form>
</div>
	</div>	
);

    }}
