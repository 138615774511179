import { WhiteSpace, List, InputItem, Button } from 'antd-mobile';
import React from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';



export default class Conferma extends React.Component{
  constructor(props){
    super(props);
    this.state = {frase: 'Caricamento...',tempo: props.match.params.tempo, email: props.match.params.email}
    }
    
  componentDidMount(){
        
    axios.get('/api/confermaopenday?email='+this.state.email+"&tempo="+ this.state.tempo).then(res => {
        if(res.data==="Non ti conosco")this.setState({frase: "Il link che hai utilizzato è corrotto o è già stato utilizzato. Se ritieni si tratti di un problema, contattaci."
       
      })
      else if(res.data==="Posti Esauriti") window.location ="/openday/postiesauriti";
      else this.setState({frase: "La tua prenotazione è stata confermata! Ti aspettiamo nell'orario che hai indicato: trovi un reminder nella tua casella di posta."});

      console.log(res);})
    }
render(){
   
    return (
      <div>
     <h1>{this.state.frase}</h1>
  </div>
);
}

}