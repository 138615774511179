import {  WhiteSpace, List, InputItem, Button } from 'antd-mobile';
import React from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';


export default class Login extends React.Component{
  state={
    email: '',
    password: '',
    error: false,
  }
  
render(){
   const faiilLogin=()=>{
     var response = {
       email: this.state.email,
       password: this.state.password
     }
    axios
    .post('/api/login', response)
    .then((res) => {
      console.log(res);
    if(res.data.token===false){
      this.setState({error: true})
    }
    else{
      this.setState({error: false})
      localStorage.setItem("COToken", res.data.token);
      window.location = "/";
    }
  }
      )
   }
    return (
      <div>
        <WhiteSpace size='xl'/>
        <WhiteSpace size='xl'/>
        <WhiteSpace size='xl'/>
      <h1 style={{fontFamily: 'Righteous'}}>
          Accedi
        </h1>
      <List >
      <InputItem
      
      name='email'
      clear
      onChange={(value)=>{this.setState({email: value}); console.log(this.state);}}
    >Email</InputItem>
    <InputItem
      type='password'
      name='password'
      clear
      onChange={(value)=>{this.setState({password: value}); console.log(this.state);}}
    >Password</InputItem>
    <WhiteSpace/>
    <WhiteSpace/>
    <Button onClick={()=>faiilLogin()}>Invia</Button>
    <WhiteSpace/>
    <WhiteSpace/>
    <Link style={{color: "#0fb340"}} to="/registrati">Non hai un account? Registrati qui!</Link>
    <WhiteSpace/>
    <Link style={{color: "#0fb340"}} to="/termini">Informativa privacy</Link>
    <WhiteSpace/>
    {this.state.error===true&&
    <p style={{color: 'red'}}>I dati inseriti non sono corretti.</p>}
      </List>
    
  </div>
);
}

}