import React from 'react';



export default class Termini extends React.Component{

 
  
render(){
   return (
      <div style={{textAlign:'left'}}>
     <h1>Informativa sulla Privacy</h1>
     <h3>Per non farci mancare nulla</h3>
      <p>Si fornisce la presente informativa ai sensi del Regolamento UE 2016/679 (GDPR), per coloro che consultano il sito web www.cavour.me. Si precisa che la presente informativa riguarda solo il sito di Cavour.me e non anche altri indirizzi web eventualmente consultati dall’utente tramite link.</p>
    <h4><b>Titolare del trattamento</b></h4>
    <p>Il “titolare” del trattamento dei dati, relativi a persone identificate o identificabili che accedono a questo sito e vi navigano, è Giulio Zingrillo (email zingrillo@pm.me). Il Liceo Cavour di Roma non ha alcuna responsabilità sul trattamento di tali dati.</p>
    <h4><b>Base giuridica del trattamento</b></h4>
    <p>Il trattamento dei dati personali da parte di Cavour.me si basa sul consenso – ai sensi dell’art. 6, par. 1, lett. a) del Regolamento UE 2016/679 – espresso dall’utente mediante la navigazione su questo sito web e la sua consultazione, così accettando la presente informativa. Il consenso è facoltativo e può essere revocato in qualsiasi momento mediante richiesta inviata a mezzo email a zingrillo@pm.me.
</p>
<h4><b>Dati trattati e finalità del trattamento</b></h4>
<p>Utilizziamo solo dati forniti volontariamente dagli utenti, e indirizzi mail resi pubblici dal Liceo Cavour di Roma. Tali dati sono trattati solo per il tempo necessario. Non facciamo uso di cookies.</p>
  </div>
);

    }}
