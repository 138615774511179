import { WhiteSpace, List, InputItem, Button } from 'antd-mobile';
import React from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';


export default class Registrati extends React.Component{
  state={
    email: '',
    success: false,
    error: false,
  }
  
render(){
   const faiilLogin=()=>{
     var response = {
       email: this.state.email,
       
     }
    axios
    .post('/api/preregister', response)
    .then((res) => {
      console.log(res);
    if(res.data.Success===false){
      this.setState({error: true});
    }
    else{
      this.setState({success: true});
    }
  }
      )
   }
    return (
      <div>
        <WhiteSpace size='xl'/>
        <WhiteSpace size='xl'/>
        <WhiteSpace size='xl'/>
      <h1 style={{fontFamily: 'Righteous'}}>
          Registrati.<br/>Ci basta la tua mail istituzionale.
        </h1>
      {this.state.success===true && <>
      <h1>Ti abbiamo inviato una mail. Clicca sull'indirizzo in essa contenuto.</h1>
      <h2>Controlla anche la spam.<br/>
      Di solito la mail arriva subito, ma può volerci qualche minuto.</h2></>}
      {this.state.success===false&&
      <List >
      <InputItem
      
      name='email'
      clear
      onChange={(value)=>{this.setState({email: value}); console.log(this.state);}}
    >Email</InputItem>
    
    <WhiteSpace/>
    <WhiteSpace/>
    <Button onClick={()=>faiilLogin()}>Invia</Button>
    <WhiteSpace/>
    <WhiteSpace/>
    <Link style={{color: "#0fb340"}} to="/accedi">Hai già un account? Accedi!</Link>
    <WhiteSpace/>
    <WhiteSpace/>
    {this.state.error===true&&
    <p style={{color: 'red'}}>La registrazione non è andata a buon fine. Controlla che l'indirizzo che hai inserito sia corretto e di non esserti già registrato.
    <br/>Molto spesso senza accorgercene mettiamo uno spazio dopo la mail, assicurati che questo spazio non ci sia.<br/>
    Se proprio non riesci, potrebbe esserci un errore di trascrizione. Contatta i Rappresentanti.</p>}
      </List>}
    
  </div>
);
}

}