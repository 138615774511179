import React from 'react';



export default class Postiesauriti extends React.Component{

 
  
render(){
   return (
      <div style={{textAlign:'center'}}>
     <h1>Mentre compilavi il modulo, o mentre rispondevi alla mail, i posti per la fascia oraria che hai selezionato sono andati esauriti. Ci scusiamo per il disagio</h1>
    
  </div>
);

    }}
