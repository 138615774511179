import React from 'react';
import { SearchBar, List, Button, WhiteSpace } from 'antd-mobile';
import axios from 'axios'


export default class Studenti extends React.Component{

 
  state = {
    value: '',
    risultato: [],
    ricerca: false,
    studente: {}
  };

  onChange= (value) => {
    this.setState({'value': value });
    console.log(this.state);
  };
 
  clear = () => {
    this.setState({ value: '' });
  };

 
render(){
  const Item = List.Item ;
  
  const visualizza=(id)=>{
      this.setState({ricerca: null});
      axios.get('/api/visualizza?id='+id, config).then(res => {
        console.log(res);
        
        this.setState({studente: res.data.studente});
      })
  }
  const inviamail=(id)=>{
    
    axios.get('/api/inviamail?id='+id, config).then(res => {
      console.log(res);
     
    })
}
  const onSubmit= (value) => {
    
    axios.get('/api/ricerca?ricerca='+this.state.value, config).then(res => {
      console.log(res);
      this.setState({ricerca: true, risultato: res.data.risposta});
    })
  }
 
  let config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("COToken"),
    },
  };
  const lista = this.state.risultato.map((name)=>
    <Item onClick={()=>visualizza(name.id)} extra={<a href={'https://instagram.com/'+name.instagram}>@{name.instagram}</a> }>{<><img style={{height:"100%"}} src={"https://eu.ui-avatars.com/api/?name="+name.nome+"&rounded=true&background=random&size=75&color=fff"}/>{name.nome}</>}</Item>
  )
    return (
      <div>
      <SearchBar 
      style={{width: "200px", marginTop:'-25%'}} 
      placeholder="Cerca uno studente"
      cancelText = "Cancella" 
      onChange = {(value)=>{this.onChange(value)}}
      onSubmit = {(value)=>{onSubmit(value)}}
      maxLength={50} />
      <div>
      {this.state.ricerca===false&&
      <p style={{marginLeft:'3%', marginRight:'3%',color:'#888', fontSize:'16px', }}>Effettua una ricerca.<br/>I risultati appariranno qui. </p>}
       
       {this.state.ricerca===true&& 
       this.state.risultato!==[] && <><WhiteSpace/><WhiteSpace/><WhiteSpace/><List style={{width:'300px'}}>{lista}</List></>}
      {this.state.ricerca===true&&this.state.risultato.length === 0 && <p style={{marginLeft:'3%', marginRight:'3%',color:'#888', fontSize:'16px', }}>Nessun risultato trovato. </p>}
      {this.state.ricerca ===null&& <><WhiteSpace size='xl'/>
              <center>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <Button style={{backgroundColor: 'black', color:'white', width:'300px'}} onClick={()=>{this.setState({ricerca:true})}}>Indietro</Button>
              <WhiteSpace size='xl'/>
              <img style={{height:"100%"}} src={"https://eu.ui-avatars.com/api/?name="+this.state.studente.name+"&rounded=true&background=0fb340&size=175&color=fff"}/>
              <WhiteSpace size='xl'/>
              <List style={{width: '300px'}}>
              <Item onClick={()=>alert(this.state.studente.name)} extra={this.state.studente.name}>Nome</Item>
              <Item onClick={()=>alert(this.state.studente.status)} extra={this.state.studente.status}>Stato</Item>
              <Item extra={<a href={'https://instagram.com/'+this.state.studente.instagram}>{"@"+this.state.studente.instagram}</a>}>Profilo Instagram</Item>
              <Item  extra={<Button onClick={()=>{alert(this.state.studente.phonenumber); inviamail(this.state.studente.id)}}>Visualizza</Button>}>Numero di telefono</Item>
              <Item onClick={()=>alert(this.state.studente.email)} extra={this.state.studente.email}>Indirizzo istituzionale</Item>
             </List>
             <p style={{marginLeft:'3%', marginRight:'3%',color:'#888', fontSize:'16px', }}>
            Per tutelare la privacy degli utenti, ogni volta che si visualizza un numero di telefono l'interessato riceve una mail.<br/>
            Se questo studente non si è registrato, non potrai vedere il suo numero di telefono, e a lui non arriverà la mail di notifica.<br/></p></center></>}
      </div>
  </div>
);
}

}