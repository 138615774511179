import { WhiteSpace, List, InputItem, Button } from 'antd-mobile';
import React from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';



export default class Registrati extends React.Component{

    

  
  constructor(props){
    super(props);
    this.state = {hash: props.match.params.hash, email: '',
    success: null,
    error: false,
    instagram: '',
    phonenumber: '',
    password: '',
    status: '',
    OK : false,}
    
}
  componentDidMount(){
        
    axios.get('/api/confirm?hash='+ this.state.hash).then(res => {
        
        console.log(res);
        if(res.data.Success===false){
          this.setState({success:false})
        }
        else{ 
          this.setState({success: true})
        }
      })
    }
render(){
   const finalizza=()=>{
    var response = {
      instagram: this.state.instagram,
      password: this.state.password,
      phonenumber: this.state.phonenumber,
      status: this.state.status,
      hash: this.state.hash,
    }
    if(this.state.password!==''){
   axios
   .post('/api/finalize', response)
   .then((res) => {
     console.log(res);
     this.setState({OK: true})
 }
     )
  }
   }
    return (
      <div>
        <WhiteSpace size='xl'/>
        <WhiteSpace size='xl'/>
        <WhiteSpace size='xl'/>
      <h1 style={{fontFamily: 'Righteous'}}>
          Registrati.<br/>Ci basta la tua mail istituzionale.
        </h1>
     {this.state.success===false&& this.state.OK===false&&
     <>
     <h1>Il codice che hai inserito è già stato utilizzato o è corrotto.</h1>
     <h2>Se il problema persiste, contattaci.</h2>
     <WhiteSpace/>
     <WhiteSpace/>
     </>}
     
     {this.state.success===true&&  this.state.OK===false&&<> <h1>Aggiungi qualche dato per completare la registrazione.</h1>
      
      <h3>Numero di telefono e instagram sono facoltativi, ma aiutano gli altri a trovarti più facilmente.<br/>
      Riceverai una mail di notifica ogni volta che qualcuno vede il tuo numero di telefono.</h3>
      <List >
      <InputItem
      clear
      placeholder="Senza la @"
      onChange={(value)=>{this.setState({instagram: value}); console.log(this.state);}}
    >Instagram</InputItem>
    <InputItem
      type="number"
    
      clear
      onChange={(value)=>{this.setState({phonenumber: value}); console.log(this.state);}}
    >Telefono</InputItem>
    <InputItem
      type="password"
      extra={"Campo Obbligatorio"}
      clear
      onChange={(value)=>{this.setState({password: value}); console.log(this.state);}}
    >Password</InputItem>
    <InputItem
     
      
      clear
      onChange={(value)=>{this.setState({status: value}); console.log(this.state);}}
    >Stato</InputItem>
    <WhiteSpace/>
    <WhiteSpace/>
    <Button onClick={()=>finalizza()}>Invia</Button>  </List>  </>}
    { this.state.OK===true&&
    <h1>La tua registrazione è completata.<br/>
    <Link style={{color: "#0fb340"}} to="/accedi">Vai al Login</Link></h1>}
    <WhiteSpace/>
    <WhiteSpace/>
    <WhiteSpace/>
  
  
    
  </div>
);
}

}