import {  WhiteSpace, List, InputItem, Button } from 'antd-mobile';
import React from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { Select } from 'antd';
const { Option } = Select;


export default class Tutor extends React.Component{
  state={
    anno: 1,
    materia: 1,
    ricerca: true,
    tutor: [],
    studente: {}
  }
render(){
  const Item = List.Item ;
   const visualizza=(id)=>{
      this.setState({ricerca: "sospesa"});
      axios.get('/api/visualizza?id='+id, config).then(res => {
        console.log(res);
        
        this.setState({studente: res.data.studente});
      });
    }
  const handleAnno=(value)=> {
    this.setState({anno: value});
    console.log(this.state);
  }
  const handleMateria=(value)=> {
    this.setState({materia: value})
  }
  let config = {
    headers: {
      Authorization: "Bearer " +localStorage.getItem("COToken"),
    },
  };
  const inviamail=(id)=>{
    
    axios.get('/api/inviamail?id='+id, config).then(res => {
      console.log(res);
     
    })
}
const invia=()=>{
 
      axios.get('/api/cercatutor?materia='+this.state.materia+"&anno="+this.state.anno, config).then(res => {
        console.log(res);
        this.setState({tutor: res.data});
        if(res.data.length===0){
          this.setState({ricerca:false})
        }
      else{ this.setState({ricerca:null})}});
} 
const lista = this.state.tutor.map((name)=>
<Item style={{cursor: 'pointer'}} onClick={()=>visualizza(name.idtutor)}>{<><img style={{ height:"100%"}} src={"https://eu.ui-avatars.com/api/?name="+name.nome+"&rounded=true&background=random&size=75&color=fff"}/>  {name.nome}</>}</Item>
)
    return (
      <div>
        <center>
      {(this.state.ricerca===true||this.state.tutor.length===0)&&
      <>
      <h2>Indica la materia in cui ti serve una mano :)</h2>
      <Select defaultValue="1" style={{ width:"40%", fontSize: "large" }} onChange={handleMateria}>
        <Option value="1">Fisica (Ponte)</Option>
        <Option value="2">Fisica</Option>
        <Option value="3">Italiano</Option>
        <Option value="4">Matematica</Option>
        <Option value="5">Inglese</Option>
        <Option value="6">Scienze</Option>
        <Option value="7">Storia</Option>
        <Option value="8">Filosofia</Option>
        <Option value="9">Latino</Option>
        <Option value="10">Cambridge</Option>
    </Select>
    <Select defaultValue="1" style={{ width:"40%", fontSize: "large" }} onChange={handleAnno}>
        <Option value="1">Primo Liceo</Option>
        <Option value="2">Secondo Liceo</Option>
        <Option value="3">Terzo Liceo</Option>
        <Option value="4">Quarto Liceo</Option>
        <Option value="5">Quinto Liceo</Option>
    </Select>
    <WhiteSpace size='xl'/>
    <WhiteSpace size='xl'/>
    <WhiteSpace size='xl'/>
    <WhiteSpace size='xl'/>
    <Button  onClick= {()=>invia()}style={{cursor:'pointer', fontFamily:'Righteous', backgroundColor: "#0fb340", color: "white"}}>Cerca Tutor</Button>
      </>}
    {this.state.ricerca===false &&
    <p>Nessun risultato trovato per questa materia e questo anno.</p>}
    {this.state.ricerca===null&&
    <> <Button style={{backgroundColor: 'black', color:'white', width:'300px'}} onClick={()=>{this.setState({ricerca:true, anno: 1, materia:1})}}>Indietro</Button>
    <WhiteSpace size='xl'/>
    <WhiteSpace size='xl'/>
    <h1>Tutor</h1><List style={{width:'300px'}}>{lista}</List></>}
    {this.state.ricerca==="sospesa"&& 
    <>
    <WhiteSpace size='xl'/>
    <WhiteSpace size='xl'/>
    <WhiteSpace size='xl'/>
    <WhiteSpace size='xl'/>
    <WhiteSpace size='xl'/>
    <WhiteSpace size='xl'/>

    <WhiteSpace size='xl'/>
    <WhiteSpace size='xl'/>
    <WhiteSpace size='xl'/>
     <Button style={{backgroundColor: 'black', color:'white', width:'300px'}} onClick={()=>{this.setState({ricerca:null})}}>Indietro</Button>
    <WhiteSpace size='xl'/>
              <img style={{height:"100%"}} src={"https://eu.ui-avatars.com/api/?name="+this.state.studente.name+"&rounded=true&background=0fb340&size=175&color=fff"}/>
              <WhiteSpace size='xl'/>
              <List style={{width: '300px'}}>
              <Item onClick={()=>alert(this.state.studente.name)} extra={this.state.studente.name}>Nome</Item>
              <Item onClick={()=>alert(this.state.studente.status)} extra={this.state.studente.status}>Stato</Item>
              <Item extra={<a href={'https://instagram.com/'+this.state.studente.instagram}>{"@"+this.state.studente.instagram}</a>}>Profilo Instagram</Item>
              <Item  extra={<Button onClick={()=>{alert(this.state.studente.phonenumber); inviamail(this.state.studente.id)}}>Visualizza</Button>}>Numero di telefono</Item>
              <Item onClick={()=>alert(this.state.studente.email)} extra={this.state.studente.email}>Indirizzo istituzionale</Item>
             </List>
             <p style={{marginLeft:'3%', marginRight:'3%',color:'#888', fontSize:'16px', }}>
            Per tutelare la privacy degli utenti, ogni volta che si visualizza un numero di telefono l'interessato riceve una mail.<br/>
            </p></>}
  </center>
  </div>
);
}

}