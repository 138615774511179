import React from 'react';
import { Toast} from 'antd-mobile';
import { List, InputItem, WhiteSpace, Button } from 'antd-mobile';
import {Link} from 'react-router-dom';
import axios from 'axios'
import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
import { Skeleton } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Select } from 'antd';
const { Option } = Select;

export default class Profilo extends React.Component{
 
  constructor(props) {
    super(props);    this.state = {
    mostra: false,
    anno: 1,
    materia: 1,
    ISBN: '',
    esiste: null,
    titolo: null,
    autori: null,
    info: null,
    faiTutor: false,
  };
  }
  toastSuccess() {
    Toast.success('Annuncio Eliminato', 3);
  }
 refreshPage = (hash)=>{
   if (hash){window.location.hash = hash}
   window.location.reload(); 
    }
 
render(){
  const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;
  const Item = List.Item ;
  const axiosInstance = axios.create({
    timeout: 20000,
    headers:  {Authorization: `Bearer `+localStorage.getItem("COToken") }
  });
  const handleAnno=(value)=> {
    this.setState({anno: value});
    console.log(this.state);
  }
  const handleMateria=(value)=> {
    this.setState({materia: value})
  }
  let config = {
    headers: {
      Authorization: "Bearer " +localStorage.getItem("COToken"),
    },
  };
  const deletePost=(n)=> {
    axios
      .delete('/api/elimina?id='+n, config)
      .then(() => {
        
        this.refreshPage("#profilo");
      });
   
  }
  const deleteTutoraggio=(n)=> {
    axios
      .delete('/api/eliminatutoraggio?id='+n, config)
      .then(() => {
        
        this.refreshPage("#profilo");
      });
   
  }
  const mostraVendi=()=>{
    this.setState({mostra: true})
  } 
  const mostraTutor=()=>{
    this.setState({faiTutor: true})
  }
  const invia=()=> {
    axios
      .get('/api/vediseesiste?isbn='+this.state.ISBN, config)
      .then((res) => {
        console.log(res);
        this.setState({esiste:res.data.Esiste, mostra: null});
        console.log(this.state);
      });
   
  }
  const inviainfo=()=>{
    axios
    .get('/api/vendi?isbn='+this.state.ISBN+"&note="+this.state.info, config)
    .then((res) => {
      Toast.success('Annuncio inserito con successo. Ricarica la pagina per vederlo qui.', 3);
      this.refreshPage("#profilo");
      this.setState({mostra: false,
        ISBN: '',
        esiste: null,
        titolo: null,
        autori: null,
        info: null,
        
      });
    });
  }
  const inviaTutor=()=>{
    axios
    .get('/api/aggiungitutoraggio?anno='+this.state.anno+"&materia="+this.state.materia, config)
    .then((res) => {
      console.log(res);
      this.refreshPage("#profilo");
      
    });
  }
  const invialibro=()=>{
    axios
    .get('/api/aggiungi?isbn='+this.state.ISBN+"&autori="+this.state.autori+"&titolo="+this.state.titolo, config)
    .then((res) => {
      console.log(res);
      this.setState({
       
        esiste: true,
        
      });
      
    });
  }
    return (
      <div> 
         <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
              <WhiteSpace size='xl'/>
        <Link to='/accedi'><h1 style={{backgroundColor: "black", color: "white"}}>Esci dal sito</h1></Link>
        <Get url="/api/me" instance={axiosInstance} >
        {(error, response, isLoading, makeRequest, axios) => {
          if(error) {
            window.location="/accedi"  }
          else if(isLoading) {
            return (<Skeleton active/>)
          }
          else if(response !== null) {
            return (<center>
             
             
              <img style={{height:"100%"}} src={"https://eu.ui-avatars.com/api/?name="+response.data.name+"&rounded=true&background=0fb340&size=175&color=fff"}/>
              <WhiteSpace size='xl'/>
              <List style={{width:'300px'}}>
              <Item extra={response.data.name}>Nome</Item>
              <Item extra={response.data.status}>Stato</Item>
              <Item extra={<a href={'https://instagram.com/'+response.data.instagram}>{"@"+response.data.instagram}</a>}>Profilo Instagram</Item>
              <Item extra={response.data.phonenumber}>Numero di telefono</Item>
              <Item extra={response.data.email}>Indirizzo istituzionale</Item>
             </List>
             <p style={{marginLeft:'3%', marginRight:'3%',color:'#888', fontSize:'16px', }}>
            Riceverai una mail ogni volta che qualcuno visualizza il tuo numero di telefono.<br>
            </br>
            Per cambiare i tuoi dati personali, come lo stato, il profilo instagram e il telefono, contatta Giulio Zingrillo.   
            </p>
            <WhiteSpace size='xl'/></center> )
          }
          return null
        }}
      </Get>
      <h1 style={{fontFamily: 'Righteous'}}>
          Tutoraggio
        </h1>
        <Get url="/api/imieitutoraggi" instance={axiosInstance} >
        {(error, response, isLoading, makeRequest, axios) => {
          if(error) {
            return (error.message)
          }
          else if(isLoading) {
            return (<Skeleton active />)
          }
          else if(response!==null&&response.data.length>0) {
            const lista = ["Fisica (Ponte)", "Fisica", "Italiano", "Matematica", "Inglese", "Scienze", "Storia", "Filosofia", "Latino", "Cambridge"];
            const listaTutoraggi = response.data.map((name)=>
            <Item extra={<Button  inline size="small" style={{ marginRight: '4px', cursor:'pointer' }} onClick={()=>{deleteTutoraggio(name.id)}}>Elimina</Button>}>{lista[name.materia-1] +", " + name.anno+"°"}</Item>)
           
            return (<center>
              <List style={{width:'300px'}}>
            <ul>{listaTutoraggi}</ul>
            </List>
            <WhiteSpace size='xl'/></center> )
          }
          return <p style={{marginLeft:'3%', marginRight:'3%',color:'#888', fontSize:'16px', }}>Al momento non contribuisci al tutoraggio.</p>;
        }}
      </Get>
      {this.state.faiTutor===false&&       <Button  onClick= {()=>mostraTutor()}style={{cursor:'pointer', fontFamily:'Righteous'}}>Dai una mano!</Button>}
      {this.state.faiTutor===true && 
      <>
      <Select defaultValue="1" style={{ width:"40%", fontSize: "large" }} onChange={handleMateria}>
      <Option value="1">Fisica (Ponte)</Option>
      <Option value="2">Fisica</Option>
      <Option value="3">Italiano</Option>
      <Option value="4">Matematica</Option>
      <Option value="5">Inglese</Option>
      <Option value="6">Scienze</Option>
      <Option value="7">Storia</Option>
      <Option value="8">Filosofia</Option>
      <Option value="9">Latino</Option>
      <Option value="10">Cambridge</Option>
  </Select>
  <Select defaultValue="1" style={{ width:"40%", fontSize: "large" }} onChange={handleAnno}>
      <Option value="1">Primo Liceo</Option>
      <Option value="2">Secondo Liceo</Option>
      <Option value="3">Terzo Liceo</Option>
      <Option value="4">Quarto Liceo</Option>
      <Option value="5">Quinto Liceo</Option>
  </Select>
  <WhiteSpace/>
  <WhiteSpace/>
  <Button  onClick= {()=>inviaTutor()}style={{cursor:'pointer', fontFamily:'Righteous', backgroundColor: "#0fb340", color: "white"}}>Aggiungi materia</Button>
  </>}
     <WhiteSpace/>
        <h1 style={{fontFamily: 'Righteous'}}>
          I miei annunci
        </h1>
        <Get url="/api/lemievendite" instance={axiosInstance} >
        {(error, response, isLoading, makeRequest, axios) => {
          if(error) {
            return (error.message)
          }
          else if(isLoading) {
            return (<Skeleton active />)
          }
          else if(response!==null&&response.data.length>0) {
            const listaISBN = response.data.map((name)=>
            <Item extra={<Button  inline size="small" style={{ marginRight: '4px', cursor:'pointer' }} onClick={()=>{deletePost(name.id)}}>Elimina</Button>}>{name.isbn}</Item>)
           
            return (<center>
              <List style={{width:'300px'}}>
            <ul>{listaISBN}</ul>
            </List>
            <WhiteSpace size='xl'/></center> )
          }
          return <p style={{marginLeft:'3%', marginRight:'3%',color:'#888', fontSize:'16px', }}>Al momento non hai annunci attivi</p>;
        }}
      </Get>
      {this.state.mostra === true &&
      <List style={{width:'300px'}}><InputItem
      name='ISBN'
      clear
      type="number"
      placeholder="non inserire lettere"
      onChange={(value)=>{this.setState({ISBN: value}); console.log(this.state);}}
    >ISBN</InputItem>
    <WhiteSpace/>
    <Button  onClick= {()=>invia()}style={{cursor:'pointer', fontFamily:'Righteous'}}>Invia</Button>
    </List>
}
 {this.state.mostra===false&&       <Button  onClick= {()=>mostraVendi()}style={{cursor:'pointer', fontFamily:'Righteous'}}>Vendi</Button>}
      {this.state.esiste===false && <List style={{width:'300px'}}>
      <h3>Sei il primo a vendere questo libro. Aggiungi un po' di dati.</h3>
      <InputItem
      name='Titolo completo'
      clear
      placeholder=""
      onChange={(value)=>{this.setState({titolo: value}); console.log(this.state);}}
    >Titolo</InputItem>
    <InputItem
      name='Autori'
      clear
      placeholder=""
      onChange={(value)=>{this.setState({autori: value}); console.log(this.state);}}
    >Autori</InputItem>
      <WhiteSpace/>
     <WhiteSpace/> 
    <Button  onClick= {()=>invialibro()}style={{cursor:'pointer', fontFamily:'Righteous'}}>Invia</Button>
      </List>}
      {this.state.esiste===true && <List style={{width:'300px'}}>
      <h3>Aggiungi, se vuoi, delle info sullo stato del tuo libro</h3>
      <InputItem
      name='Info'
      clear
      placeholder=""
      onChange={(value)=>{this.setState({info: value}); console.log(this.state);}}
    >Info</InputItem>
  
      <WhiteSpace/>
     <WhiteSpace/> 
    <Button  onClick= {()=>inviainfo()}style={{cursor:'pointer', fontFamily:'Righteous'}}>Invia</Button>
      </List>}
      <WhiteSpace/>
     <WhiteSpace/> 
  </div>
);
}
}