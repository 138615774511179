import 'antd-mobile/dist/antd-mobile.css';
import 'antd/dist/antd.css';
import './App.css';
import React from 'react';
import Menu from './features/menu';
import Login from './features/login';
import Finalizza from './features/finalizza';
import Registrati from './features/registrati';
import Termini from './features/termini';
import OpenDay from './features/openday';
import Dettagli from './features/dettagli';
import Benfatto from './features/benfatto';
import Conferma from './features/conferma';
import Postiesauriti from './features/postiesauriti';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';


function App() {
  return (
    <div className="App">
    <Router>
      <header style={{fontFamily: 'Righteous', height:'15%', padding:'2%', backgroundColor:'#0fb340', fontFace:'Verdana', fontSize:'xx-large', marginBottom:'0%', zIndex:900, width:'100%', marginTop:0}}>
        <span style={{color:'white'}}>
        Cavour
        </span>.me
      </header>
    <Switch>
      <Route path='/openday' exact component = {OpenDay}/>
      <Route path='/openday/dettagli' exact component = {Dettagli}/>
      <Route path='/openday/ben_fatto' exact component = {Benfatto}/>
      <Route path='/openday/postiesauriti' exact component = {Postiesauriti}/>
      <Route path='/openday/conferma/:tempo/:email' component = {Conferma}/>
      <Route path='/' exact component={Menu}/>
      <Route path='/accedi' component={Login}/>
      <Route path='/termini' component={Termini}/>
      <Route path='/registrati'exact component={Registrati}/>
      <Route path={'/registrati/:hash'} component={Finalizza}/>
      <Route>
        <h1>Pagina non trovata. Ti consigliamo di tornare alla pagina principale.</h1>
      </Route>
    </Switch>
    <footer style={{color: 'white',fontFamily: 'Righteous', height:'5%', padding:'2%', backgroundColor:'#0fb340', fontFace:'Verdana', fontSize:'small', marginBottom:'0%', width:'100%', marginTop:0}}>
      Liceo Cavour di Roma - Tutti i diritti riservati
    </footer>
  </Router>
    </div>
  );
}

export default App;
