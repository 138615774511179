import React from 'react';



export default class Dettagli extends React.Component{

 
  
render(){
   return (
      <div style={{textAlign:'left'}}>
        <h1>Informativa sul Progetto "Open Day" del Liceo Cavour di Roma</h1>
        <p>Il progetto nasce per offrire alle famiglie e agli studenti delle scuole medie la possibilità di vivere concretamente la realtà scolastica del nostro Liceo. Tramite prenotazione su qesto sito sarà infatti possibile visitare per mezz'ora, in uno dei tre sabati indicati, i locali del nostro Istituto, assistere alle lezioni e respirare l'aria del Cavour.</p>
        <b>Gli studenti dovranno essere accompagnati da uno e un solo genitore, o tutore, munito di Green Pass.</b>
        <br/>
     <h1>Informativa sulla Privacy</h1>
     
      <p>Si fornisce la presente informativa ai sensi del Regolamento UE 2016/679 (GDPR), per coloro che consultano il sito web www.cavour.me. Si precisa che la presente informativa riguarda solo il sito di Cavour.me e non anche altri indirizzi web eventualmente consultati dall’utente tramite link.</p>
    <h4><b>Titolare del trattamento</b></h4>
    <p>Il “titolare” del trattamento dei dati, relativi a persone identificate o identificabili che accedono a questo sito e vi navigano, è Giulio Zingrillo (email zingrillo@pm.me). Il Liceo Cavour di Roma non ha alcuna responsabilità sul trattamento di tali dati.</p>
    <h4><b>Base giuridica del trattamento</b></h4>
    <p>Il trattamento dei dati personali da parte di Cavour.me si basa sul consenso – ai sensi dell’art. 6, par. 1, lett. a) del Regolamento UE 2016/679 – espresso dall’utente mediante la navigazione su questo sito web e la sua consultazione, così accettando la presente informativa. Il consenso è facoltativo e può essere revocato in qualsiasi momento mediante richiesta inviata a mezzo email a zingrillo@pm.me.
</p>
<h4><b>Dati trattati e finalità del trattamento</b></h4>
<p>Utilizziamo solo dati forniti volontariamente dagli utenti. Tali dati sono trattati solo per il tempo necessario. Non facciamo uso di cookies.</p>
  </div>
);

    }}
