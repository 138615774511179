import React from 'react';



export default class Benfatto extends React.Component{

 
  
render(){
   return (
      <div style={{textAlign:'center'}}>
      <br/>
      <br/>
      <img src='http://ultra.altervista.org/accoglienza/email.png' style={{height: '400px'}}/>
     <h1>Ti abbiamo inviato una mail</h1>
     
      <p>Clicca sul link in essa contenuto per prenotare il tuo posto. Controlla anche la spam. La mail arriverà da Cavour.Online: potrebbe metterci qualche minuto.</p>

  </div>
);

    }}
